
import { Delegates, Events } from "@/api";
import { DelegateEventResults } from "@/api/types";
import { questionType } from "@/utils/constants";
import moment from "moment";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import VideoListModal from "../onlineCourses/VideoListModal.vue";

@Component({ components: { VideoListModal } })
export default class ViewFilesModal extends Vue {
  @Prop()
  eventId!: string;
  @Prop()
  delegateId!: string;
  @Prop({ required: true })
  eventStartDate!: string;

  essayGrade = 0;
  formativeGrade = 0;
  practicalGrade = 0;

  loading = false;
  downloading:
    | "results"
    | "formative"
    | "practical"
    | "essay"
    | "records"
    | "docs"
    | null = null;
  uploading: "formative" | "practical" | "essay" | null = null;
  grading: "formative" | "practical" | "essay" | null = null;
  approving = false;
  info: DelegateEventResults = {} as DelegateEventResults;
  questionTypes = questionType;

  videoList: string[] = [];

  graduatedDate = new Date().toISOString().slice(0, 10);
  menu = false;

  get grade() {
    return this.info.grade >= 0 ? this.info.grade : "-";
  }

  @Watch("eventId")
  handleEventChange() {
    this.fetchInfo();
  }
  @Watch("delegateId")
  handleDelegateChange() {
    this.fetchInfo();
  }

  async fetchInfo() {
    if (this.eventId && this.delegateId) {
      this.loading = true;
      try {
        const res = await Delegates.getDelegateResults(
          this.eventId,
          this.delegateId
        );
        this.info = res;
      } catch (error) {
        const err = error as any;
        this.$error(
          err.message ? { err: err.message } : "Something went wrong"
        );
      } finally {
        this.loading = false;
      }
    }
  }

  get isQualification() {
    return this.info?.qualification_type === "Qualification";
  }
  get essayTabName() {
    return this.isQualification
      ? "Essay, Case Studys & Reflexive Questions"
      : "Essay Assessment";
  }

  get formattedEventStartDate() {
    return moment(this.eventStartDate, "DD-MM-YYYY").format("YYYY-MM-DD");
  }
  get formattedGraduatedDate() {
    return moment(this.graduatedDate).format("DD-MM-YYYY");
  }

  onCloseModal() {
    this.close();
  }

  openApproveGraduateDateSelectModal() {
    this.$modal.show("confirm-approve-graduate-date-modal");
  }
  closeApproveGraduateDateSelectModal() {
    this.$modal.hide("confirm-approve-graduate-date-modal");
    this.graduatedDate = new Date().toISOString().slice(0, 10);
  }

  async approve() {
    try {
      this.approving = true;
      await Events.approveDelegateGrade(
        this.eventId,
        this.delegateId,
        this.formattedEventStartDate
          ? this.formattedEventStartDate
          : this.eventStartDate,
        this.graduatedDate
      );
      this.$success("Grade has been approved");
      this.closeApproveGraduateDateSelectModal();
      this.$emit("approved");

      this.close();
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors || { error: err.result.message });
    } finally {
      this.approving = false;
    }
  }

  async reject() {
    try {
      await Events.rejectDelegateGrade(this.eventId, this.delegateId);
      this.$success("Grade has been rejected");
      this.close();
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors || { error: err.result.message });
    }
  }

  close() {
    this.$modal.hide("files-modal");
  }

  formateDate(date: string): string {
    if (date) {
      return moment(date).format("DD-MM-YYYY");
    }
    return "-";
  }

  async downloadChat() {
    // try {
    // await Events.downloadOnlineCourseDocs(this.eventId, this.delegateId);
    // this.$success("Docs has been downloaded");
    // } catch (e) {
    // const err = e as any;
    //   await this.$error(err.errors || { error: err.result.message });
    // }
  }

  async downloadDocs() {
    this.downloading = "docs";
    try {
      const docs = await Events.downloadOnlineCourseDocs(
        this.eventId,
        this.delegateId
      );
      if (docs) {
        this.downloadFile(docs, "docs");
      }
      this.$success("Docs have been downloaded");
    } catch (e) {
      const err = e as any;

      const errText = JSON.parse(await err.response.data.text());
      this.$error(
        (errText?.result?.message && { err: errText.result.message }) || {
          err: "Documents not found"
        }
      );
    } finally {
      this.downloading = null;
    }
  }

  async downloadRecords() {
    this.downloading = "records";
    try {
      const recs = await Events.downloadOnlineCourseRecords(
        this.eventId,
        this.delegateId
      );
      if (recs) {
        this.videoList = recs;
        this.$modal.show("video-files-modal");
      }
    } catch (e) {
      const err = e as any;
      this.$error(
        (err?.result?.message && { err: err.result.message }) || {
          err: "Records not found"
        }
      );
    } finally {
      this.downloading = null;
    }
  }

  async downloadResultsPdf() {
    this.downloading = "results";
    try {
      const recs = await Events.downloadResultsPdf(
        this.eventId,
        this.delegateId
      );
      if (recs) {
        this.downloadFile(recs, "results");
      }
      this.$success("Results has been downloaded");
    } catch (e) {
      const err = e as any;

      const errText = JSON.parse(await err.response.data.text());
      this.$error(
        (errText?.result?.message && { err: errText.result.message }) || {
          err: "Results not found"
        }
      );
    } finally {
      this.downloading = null;
    }
  }

  async downloadFormativePdf() {
    this.downloading = "formative";
    try {
      const formative = await Events.downloadFormativePdf(
        this.eventId,
        this.delegateId
      );
      if (formative) {
        this.downloadFile(formative, "formative");
      }
      this.$success("Formative Assessment has been downloaded");
    } catch (e) {
      const err = e as any;

      const errText = JSON.parse(await err.response.data.text());
      this.$error(
        (errText?.result?.message && { err: errText.result.message }) || {
          err: "Formative Assessment pdf not found"
        }
      );
    } finally {
      this.downloading = null;
    }
  }
  async downloadPracticalPdf() {
    this.downloading = "practical";
    try {
      const formative = await Events.downloadPracticalPdf(
        this.eventId,
        this.delegateId
      );
      if (formative) {
        this.downloadFile(formative, "practical");
      }
      this.$success("Practical Assessment has been downloaded");
    } catch (e) {
      const err = e as any;

      const errText = JSON.parse(await err.response.data.text());
      this.$error(
        (errText?.result?.message && { err: errText.result.message }) || {
          err: "Practical Assessment pdf not found"
        }
      );
    } finally {
      this.downloading = null;
    }
  }
  async downloadEssayPdf() {
    this.downloading = "essay";
    try {
      const essay = await Events.downloadEssayPdf(
        this.eventId,
        this.delegateId
      );
      if (essay) {
        this.downloadFile(essay, "essay");
      }
      this.$success(`${this.essayTabName} has been downloaded`);
    } catch (e) {
      const err = e as any;

      const errText = JSON.parse(await err.response.data.text());
      this.$error(
        (errText?.result?.message && { err: errText.result.message }) || {
          err: `${this.essayTabName} not found`
        }
      );
    } finally {
      this.downloading = null;
    }
  }

  downloadFile(file: any, name: string) {
    const downloadUrl = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement("a");

    const type = file.type.split("/");
    const ext = type[type.length - 1];
    const fileName = `${name}.${ext}`;
    // const ext = file.type==="application/pdf" ? 'pdf':'zip'

    link.href = downloadUrl;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  formatedAnswer(variant: string, question: any) {
    if (question.type !== "correspondence") return variant;
    // const answer = question.answers.find((answer: any) =>
    //   answer.includes(variant)
    // );
    const answerKey = Object.keys(question.answer).find(key =>
      key.includes(variant)
    );
    return answerKey ? answerKey.replace(variant, "") : "-";
  }
  isAnswerCorrect(variant: string, question: any) {
    const answerKey = Object.keys(question.answer).find(key =>
      key.includes(variant)
    );

    if (answerKey) {
      if (answerKey.replace(variant, "") === "-") return null;
      return question.answer[answerKey];
    }
    // const answer = question.answers.find((answer: any) =>
    //   answer.includes(variant)
    // );
    // if (answer) {
    //   if (question.answer[answer]) return true;
    //   else return false;
    // }
    return null;
  }

  handleEssaySelect() {
    (this.$refs.essayInput as Vue & { click: () => void }).click();
  }

  handleFormativeSelect() {
    (this.$refs.formativeInput as Vue & { click: () => void }).click();
  }
  handlePracticalSelect() {
    (this.$refs.practicalInput as Vue & { click: () => void }).click();
  }

  async uploadEssay(event: any) {
    this.uploading = "essay";
    const file = event.target.files[0];
    try {
      await Events.uploadEssayPdf(this.eventId, this.delegateId, file);

      this.$success(`${this.essayTabName} has been uploaded`);
    } catch (e) {
      const err = e as any;

      this.$error({
        err:
          err.response?.data?.result?.message ||
          `${this.essayTabName} not found`
      });
    } finally {
      this.uploading = null;
    }
  }

  async uploadFormative(event: any) {
    this.uploading = "formative";
    const file = event.target.files[0];
    try {
      await Events.uploadFormativePdf(this.eventId, this.delegateId, file);

      this.$success("Formative Assessment has been uploaded");
    } catch (e) {
      const err = e as any;

      this.$error({
        err: err.response?.data?.result?.message || "Something went wrong"
      });
    } finally {
      this.uploading = null;
    }
  }

  async uploadPractical(event: any) {
    this.uploading = "practical";
    const file = event.target.files[0];
    try {
      await Events.uploadPracticalPdf(this.eventId, this.delegateId, file);

      this.$success("Practical Assessment has been uploaded");
    } catch (e) {
      const err = e as any;

      this.$error({
        err: err.response?.data?.result?.message || "Something went wrong"
      });
    } finally {
      this.uploading = null;
    }
  }

  async setEssayGrade() {
    this.grading = "essay";
    try {
      await Events.setEssayGrade(
        this.eventId,
        this.delegateId,
        this.essayGrade
      );

      this.$success("Grade has been set");
    } catch (e) {
      const err = e as any;

      this.$error(
        err.response?.data?.errors || {
          err: err.response?.data?.result?.message || "Something went wrong"
        }
      );
    } finally {
      this.grading = null;
    }
  }
  async setFormativeGrade() {
    this.grading = "formative";
    try {
      await Events.setFormativeGrade(
        this.eventId,
        this.delegateId,
        this.formativeGrade
      );

      this.$success("Formative Grade has been setted");
    } catch (e) {
      const err = e as any;

      this.$error(
        err.response?.data?.errors || {
          err: err.response?.data?.result?.message || "Something went wrong"
        }
      );
    } finally {
      this.grading = null;
    }
  }
  async setPracticalGrade() {
    this.grading = "practical";
    try {
      await Events.setPracticalGrade(
        this.eventId,
        this.delegateId,
        this.practicalGrade
      );

      this.$success("Practical Grade has been setted");
    } catch (e) {
      const err = e as any;

      this.$error(
        err.response?.data?.errors || {
          err: err.response?.data?.result?.message || "Something went wrong"
        }
      );
    } finally {
      this.grading = null;
    }
  }
}
